/*
 https://v4-alpha.getbootstrap.com/layout/overview/
*/

.l-leftcolumn {
  &__profile {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__myevent {
    display: block;
  }

  &__myarticle {
    display: block;
  }
}

.l-threecolumn {
  @media screen and (min-width: 768px) {
    &__container {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 1500px;
      margin-left: auto;
      margin-right: auto;
    }

    &__main {
      flex: 0 0 75%;
      max-width: 75%;
      padding-left: 16px;
      padding-right: 16px;
    }

    &__menu {
      flex: 0 0 25%;
      max-width: 25%;
      padding-left: 16px;
      padding-right: 16px;
    }

    // &__advertisement{
    //     flex:0 0 25%;
    //     max-width: 25%;
    //     padding-left:16px;
    //     padding-right:16px;
    // }
  }

  @media screen and (max-width: 767px) {
    &__container {
      display: flex;
      flex-direction: column;
    }

    &__main {
      order: 0;
      padding-left: 16px;
      padding-right: 16px;
      min-height: 60vh;
    }

    &__menu {
      order: 1;
      padding-top: 32px;
      padding-left: 16px;
      padding-right: 16px;
    }

    &__advertisement {
      order: 2;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.l-twocolumn {
  @media screen and (min-width: 768px) {
    &__container {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 1500px;
      margin-left: auto;
      margin-right: auto;
    }

    &__main {
      flex: 0 0 75%;
      max-width: 75%;
      padding-left: 16px;
      padding-right: 16px;
    }

    &__menu {
      flex: 0 0 25%;
      max-width: 25%;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    &__container {
      display: flex;
      flex-direction: column;
    }

    &__main {
      order: 0;
      padding-left: 16px;
      padding-right: 16px;
      min-height: 60vh;
    }

    &__menu {
      order: 1;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
