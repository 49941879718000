// palatte
$color-purple-100: #f6f6fa;
$color-purple-200: #e6e4ef;
$color-purple-300: #b8b3d6;
$color-purple-400: #9f98c7;
$color-purple-500: #7067ac;
$color-purple-600: #4f4592;
$color-purple-700: #302477;
$color-purple-800: #190f56;
$color-purple-900: #100a38;

$color-gray-100: #f5f6f8;
$color-gray-200: #e3e6ec;
$color-gray-300: #b5b9c1;
$color-gray-400: #9da0a8;
$color-gray-500: #6e7585;
$color-gray-600: #4e5463;
$color-gray-700: #353d4c;
$color-gray-800: #292e39;
$color-gray-900: #101829;

$base-background-color: $color-gray-100;
$color-dark-sky-blue: #0275d8;
$color-dark-sky-blue-two: #3498db;
$color-tomato: #e74c3c;
$color-squash: #f39c12;
$color-greenish-teal: #2ecc71;
$color-topaz: #1abc9c;
$color-golden-rod: #f1c40f;
$color-turquoise: #09c199;
$color-watermelon: #f85359;
$color-water-blue: #1991eb;
$color-gray: #707070;
$color-gray-light: #f7f7f7;
$color-black: #333333;

$color-brand-100: #dce4f4;
$color-brand-200: #f0f4fb;

// brand
$color-brand: #355fbc;
$color-brand-secondary: #434343;
$color-accent: #cd4184;
$color-brand-alert: $color-tomato;
$color-brand-light-gray: $color-gray;
$color-brand-prop: #1ba55a;
$color-brand-prop-light: #e2f6eb;
$color-brand-opp: $color-tomato;
$color-brand-opp-light: #ffebee;
$color-brand-debate: $color-purple-800;
$color-brand-debate-light: $color-purple-100;
// other
$color-team-gov: #575bbc;
$color-team-gov-light: #ebf0f9;
$color-team-opp: #cd4163;
$color-team-opp-light: #fdf2f4;
$coler-border-weak: #e9edf1;
$color-text-weak: $color-gray-500;
//ui
$color-ui-border: $color-gray-200;
$color-ui-turn-on: $color-greenish-teal;
$color-ui-deprioritize: $color-brand-debate-light;
$color-ui-alert: #df3d1b;

$color-heading: $color-gray-700;
$color-heading-sub: $color-text-weak;
