// Icon
//
// User icon.
//
// Markup:
// <img class="c-icon {{modifier_class}}" src="http://lorempixel.com/150/150">
//
// .is-small - small
//
// Styleguide component.2
.c-icon {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  vertical-align: middle;

  &.is-small {
    width: 28px;
    height: 28px;
  }
}

.ParticipateIcon {
  &__going {
    color: green;
    background-color: white;
  }
  &__maybe {
    color: #ffd700;
    background-color: white;
  }
  &__invited {
    color: white;
    background-color: #ff1493;
    padding: 4px 1px 3px 2px;
  }
  &__notselected {
    color: black;
    background-color: white;
  }
}
