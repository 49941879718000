.l-footer {
  min-height: 56px;

  background-color: $color-brand-secondary;
  color: #fff;

  &__copyright {
    padding: 0;
    margin: 0;
    text-align: center;
    line-height: 56px;
  }
}
