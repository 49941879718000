.p-nav-sm {
  margin-top: 7px;
  background-color: darken($color-brand, 5%);
  box-shadow: 0px 2px 3.84px 0.16px rgba(83, 83, 83, 0.29);

  &__items {
    padding: 0;
    margin: 0;
    list-style: none;
    @include clearfix();
  }

  &__item {
    width: 25%;
    float: left;
    text-align: center;
  }

  &__anchor {
    display: block;
    color: #fff;
    font-size: 1.8rem;
    line-height: 2;
    border-bottom: 2px solid darken($color-brand, 5%);
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out;

    &:hover,
    &:active,
    &:focus {
      background-color: $color-brand;
      border-color: $color-brand;
      color: #fff;
    }

    &.is-active {
      border-color: #fff;
    }
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}
