.EventContext {
  &__motion {
    position: relative;
    small {
      padding: 2px;
      position: absolute;
      top: 3px;
      left: 3px;
      font-size: small;
      background-color: #dcdcdc;
    }
    span {
      margin-left: 40px;
      font-size: large;
      padding: 10px;
      font-style: oblique;
      background-color: #f0f8ff;
    }
  }

  &__head {
    position: relative;

    .eventtype_img {
      @media screen and (max-width: 480px) {
        img {
          width: 100% !important;
        }
      }
      @media screen and (min-width: 481px) {
        display: inline-block;
        img {
          width: 200px;
          height: auto;
        }
      }
    }
    .event_type_caption {
      font-style: oblique;
      @media screen and (min-width: 481px) {
        display: inline-block;
        font-size: x-large;
        margin-left: 20px;
      }
      @media screen and (max-width: 480px) {
        display: block;
        font-size: large;
        text-align: center;
        width: 100%;
      }
    }

    .group_name {
      position: absolute;
      color: gray;
      bottom: 2px;
      right: 10px;
    }
  }

  &__each_container {
    position: relative;
    width: 100%;
    .manual {
      position: absolute;
      top: -1.1rem;
      right: 0px;
      font-size: large;
      padding: 5px;
      background-color: yellow;
      .pc_message {
        @media screen and (max-width: 399px) {
          display: none;
        }
      }
      .sp_message {
        @media screen and (min-width: 400px) {
          display: none;
        }
      }
    }
  }

  &__creator {
    position: relative;
    width: 100%;
    height: 70px;
    padding: 10px 0px;
    .name {
      font-weight: bold;
    }
    .info {
      position: absolute;
      top: 25px;
      margin-left: 10px;
    }
  }
}
