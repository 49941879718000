.DebateHeadingText {
  margin-bottom: 0;
  font-weight: bold;
  color: $color-brand-debate;
  line-height: 1.3333;
}

.DebateHeadingText.-lv2 {
  font-size: 24px;
}

.DebateHeadingText.-lv3 {
  font-size: 20px;
}

.DebateHeadingText.-lv4 {
  font-size: 16px;
}

.DebateHeadingText.-lv5 {
  font-size: 14px;
}

.DebateHeadingText.-color-inherit {
  color: inherit;
}
