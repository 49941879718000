#sentence-comment {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  max-width: 80vw;
  height: 100vh;
  padding-left: 30px;
  padding-right: 30px;
  overflow-y: auto;
  background-color: #fff;
  border-right: solid;
  border-width: 1px;
}
