.AllocationStep {
  margin-bottom: 4px;
  padding-left: 0;
  display: flex;
  justify-content: center;
  counter-reset: allocate-user-room-step;
  list-style: none;
}

.AllocationStep__item {
  flex: 0 0 auto;
  counter-increment: allocate-user-room-step;
  color: $color-gray-300;
}

.AllocationStep__item::before {
  content: counter(allocate-user-room-step);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  border-radius: 50%;
  color: $color-gray-300;
  background-color: $color-gray-100;
  font-weight: normal;
  font-size: 12px;
}

.AllocationStep__item[aria-current="page"] {
  color: $color-black;
  font-weight: bold;
}

.AllocationStep__item[aria-current="page"]::before {
  color: #fff;
  font-weight: bold;
  background-color: $color-brand-debate;
}

.AllocationStep__item + .AllocationStep__item {
  margin-left: 12px;
}
