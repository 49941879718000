.p-general-comment {
  margin: 0;

  &__heading {
    margin: 0;
    margin-bottom: 21px;
    color: $color-brand;
    font-size: 1.8rem;
  }
}
