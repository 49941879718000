$container_size: 360px;

.Choice {
  &__each {
    margin: 5px;
    border: solid;
    float: left;
    text-align: center;
    width: calc(#{$container_size} / 2 - 20px);
    padding-bottom: 10px;

    .userimg {
      height: 100px;
      // width:100px;
    }
    .icon {
      height: 100px;
    }
    video {
      height: 100px;
      width: 100px;
    }
    i {
      color: gray;
    }
    input {
      display: none;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }
  &__each-title {
    font-size: 1rem;
  }
  &__each-detail {
    font-size: 0.8rem;
  }
  &__each.active {
    background-color: yellow;
    .selected {
      display: block;
    }
    i {
      color: green;
      font-size: x-large;
    }
  }
}
