// Textarea
.mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
  padding: 0;
  border-top: none;
}

textarea.mat-mdc-input-element,
input.mat-mdc-input-element {
  border-radius: 4px !important;
  border: 1px solid #ced4da !important;
  line-height: 1.5 !important;
  font-size: 14px !important;
  transition: box-shadow 150ms ease-in !important;
  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

// .mdc-text-field--filled {
.mdc-text-field {
  background-color: white !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: white !important;
}

.mdc-line-ripple {
  display: none;
}
.mat-mdc-form-field-infix {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  min-height: 0px;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 10px !important;
}

.mdc-text-field {
  padding: 10px 2px 2px 2px !important;
  // padding: 2px !important;
}

.mdc-text-field .mdc-floating-label {
  z-index: 10 !important;
}

.mat-mdc-floating-label {
  left: 0.3em !important;
}

// .mat-form-field-wrapper {
//   padding-bottom: 0;
// }

// .mat-form-field-appearance-legacy .mat-form-field-wrapper {
//   padding-bottom: 1px !important;
// }

.snackbar-class-livevideo-introduction.mat-mdc-snack-bar-container {
  color: #fff !important;
  background-color: $color-ui-alert !important;
}

.snackbar-class-livevideo-introduction .mat-mdc-simple-snack-bar {
  font-size: 20px !important;
  line-height: 1.3 !important;
}

.snackbar-class-livevideo-introduction .mat-mdc-snack-bar-label {
  color: #fff !important;
  background-color: $color-ui-alert !important;
  font-size: 20px !important;
  line-height: 1.3 !important;
}

.snackbar-class-livevideo-debate.mat-mdc-snack-bar-container {
  color: #fff !important;
  background-color: $color-ui-alert !important;
}

.snackbar-class-livevideo-debate .mat-mdc-simple-snack-bar {
  font-size: 20px !important;
  line-height: 1.3 !important;
}

.snackbar-class-livevideo-debate .mat-mdc-snack-bar-label {
  color: #fff !important;
  background-color: $color-ui-alert !important;
  font-size: 20px !important;
  line-height: 1.3 !important;
}

.cdk-overlay-pane {
  max-width: 99vw !important;
}

.owl-date-time-inline.is-flat .owl-dt-inline-container {
  box-shadow: none !important;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.owl-date-time-inline.is-invalid .owl-dt-inline-container {
  border-color: $danger;
}
