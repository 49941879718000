.AnchorBold {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}
