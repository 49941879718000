.btn > .fa:first-child,
.btn > .fas:first-child,
.btn > .fab:first-child {
  margin-right: 4px;
}

.btn.btn-no-pad-side {
  padding-left: 0;
  padding-right: 0;
}

.btn.btn-debate-primary {
  color: #fff;
  background-color: $color-brand-debate;
}

.btn.btn-debate-primary:hover,
.btn.btn-debate-primary:focus {
  background-color: darken($color-brand-debate, 10%);
}

.btn.btn-debate-primary:active {
  background-color: darken($color-brand-debate, 50%);
}

.hr.hr-strong {
  background-color: $color-gray-200;
}
