.p-chat-toolbar {
  overflow: hidden;
  margin-top: 4px;
  margin-bottom: 7px;
  background-color: #fff;
  border-radius: 10px;

  @include media-breakpoint-up(md) {
    border-radius: 20px;
  }
}
