.c-comment {
  margin-bottom: 42px;

  &__icon {
    margin-right: 7px;
    margin-bottom: 14px;
  }

  &__name {
    font-family: "Merriweather", serif;
  }

  &__like {
    float: right;
    margin-top: 35px;
  }

  &__body {
    font-family: "Merriweather", serif;
  }

  &.is-small &__icon {
    margin-bottom: 7px;
  }

  &.is-small .c-icon {
    width: 21px;
    height: 21px;
  }

  &.is-small &__like {
    margin-top: 7px;
  }
}
