.p-general-comment-post {
  &__heading {
    font-size: 1.4rem;
  }

  &__textarea {
    width: 100%;
  }

  &__btn-wrapper {
    text-align: center;
    vertical-align: bottom;
  }

  &__btn {
    margin-top: 7px;
    width: 112px;
  }

  @include media-breakpoint-up(sm) {
    &__btn-wrapper,
    &__textarea-wrapper {
      display: table-cell;
    }

    &__textarea-wrapper {
      width: 100%;
      padding: 0;
      padding-right: 14px;
    }

    &__textarea {
      width: 100%;
    }

    &__btn-wrapper {
      width: 1%;
    }

    &__btn {
      margin-top: 0;
    }
  }
}
