.p-chat {
  margin-bottom: 5px;

  &__body {
    position: relative;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 12px;

    @media screen and (min-width: 768px) {
      margin-right: 49px;
      padding-left: 28px;
      padding-right: 28px;
    }

    @media screen and (max-width: 767px) {
      margin-right: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .c-like {
      position: absolute;
      top: -24px;
      right: 6px;
    }
  }

  &__body:before {
    content: "";
    position: absolute;
    top: -8px;
    left: 27px;
    margin-left: -9px;
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 7px 9px 7px;
    z-index: 0;
  }

  &__sentense {
    font-family: "Merriweather", serif;
    position: relative;

    &.is-active {
      color: $color-brand;
    }
  }

  &__sentense-comment {
    position: absolute;

    @media screen and (min-width: 768px) {
      right: -77px;
    }

    @media screen and (max-width: 767px) {
      right: -55px;
    }

    display: block;
    z-index: 0;
    width: 30px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 3px;
    text-align: center;
    font-weight: bold;
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out, color 0.2s ease-out;
    background-color: #fff;
    line-height: 18px;

    &:hover &:active,
    &:hover {
      background-color: $color-brand;
      border-color: $color-brand;
      color: #fff;
      text-decoration: none;

      &:before {
        border-color: transparent $color-brand transparent transparent;
      }

      &:after {
        border-color: transparent $color-brand transparent transparent;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: -5px;
      margin-top: -4px;
      display: block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 3px 5px 3px 0;
      border-color: transparent #fff transparent transparent;
      transition: border-color 0.2s ease-out;
      z-index: 0;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: -6px;
      margin-top: -5px;
      display: block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 4px 6px 4px 0;
      border-color: transparent #ddd transparent transparent;
      transition: border-color 0.2s ease-out;
      z-index: -1;
    }
  }

  &.is-prop {
    .p-chat__body {
      background-color: $color-brand-prop-light;
    }

    .p-chat__body:before {
      border-color: transparent transparent $color-brand-prop-light transparent;
    }
  }

  &.is-opp {
    .p-chat__body {
      background-color: $color-brand-opp-light;
      margin-right: 0;

      @media screen and (min-width: 768px) {
        margin-left: 49px;
      }

      @media screen and (max-width: 767px) {
        margin-left: 20px;
      }

      .c-like {
        right: auto;
        left: 6px;
      }
    }

    .p-chat__body:before {
      border-color: transparent transparent $color-brand-opp-light transparent;
      left: auto;
      right: 21px;
    }

    .p-chat__sentense-comment {
      right: auto;

      @media screen and (min-width: 768px) {
        left: -77px;
      }

      @media screen and (max-width: 767px) {
        left: -55px;
      }

      &:before {
        left: auto;
        right: -5px;
        border-width: 3px 0 3px 5px;
        border-color: transparent transparent transparent #fff;
      }

      &:after {
        left: auto;
        right: -6px;
        border-width: 4px 0 4px 6px;
        border-color: transparent transparent transparent #ddd;
      }

      &:hover &:active,
      &:hover {
        &:before {
          border-color: transparent transparent transparent $color-brand;
        }

        &:after {
          border-color: transparent transparent transparent $color-brand;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
  }
}
