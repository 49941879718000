@charset "UTF-8";
// ==========================================================================
// Foundation
// ==========================================================================
@import "sass/base/variables";
// material design scss
// @import "~@angular/material/prebuilt-themes/indigo-pink.css"; // this is not necessary as it is set in angular.json
@import "~@angular/cdk/overlay-prebuilt.css";
// date time picker for  https://github.com/DanielYKPan/date-time-picker#description
// @import "~ng-pick-datetime/assets/style/picker.min.css";

// TODO: @danielmoncada/angular-datetime-picker can be imported from node_mdules but not succeeded, it is now directly put on assets folder and inde.html link is used.
// @import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i");
@import "sass/foundation/override_bootstrap_variables";
@import "sass/foundation/lib/bootstrap/bootstrap";
@import "sass/foundation/override_bootstrap";
@import "sass/foundation/override_angular_material";
@import "sass/foundation/base";
// ==========================================================================
// Theme
// ==========================================================================
@import "sass/foundation/lib/theme_variable";
@import "sass/foundation/lib/theme";
// ==========================================================================
// Layout
// ==========================================================================
@import "sass/layout/header";
@import "sass/layout/footer";
@import "sass/layout/main";
@import "sass/layout/container";
@import "sass/layout/sidebar";
@import "sass/layout/sentence-comment";
@import "sass/layout/web-layout";
// ==========================================================================
// Object
// ==========================================================================
// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------
@import "sass/object/component/AnchorBold";
@import "sass/object/component/like";
@import "sass/object/component/badge";
@import "sass/object/component/breadcrumb";
@import "sass/object/component/btn";
@import "sass/object/component/modal";
@import "sass/object/component/comment";
@import "sass/object/component/icon";
@import "sass/object/component/textarea";
@import "sass/object/component/toast";
@import "sass/object/component/loading";
@import "sass/object/component/social";
@import "sass/object/component/Spacer";
// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------
@import "sass/object/project/AllocationStep";
@import "sass/object/project/AuditriumAllocationModal";
@import "sass/object/project/header";
@import "sass/object/project/menu-account";
@import "sass/object/project/nav-sm";
@import "sass/object/project/main";
@import "sass/object/project/debate";
@import "sass/object/project/debate-intro";
@import "sass/object/project/debate-group";
@import "sass/object/project/DebateHeadingText";
@import "sass/object/project/DebatePanelMain";
@import "sass/object/project/argument";
@import "sass/object/project/argument-header";
@import "sass/object/project/argument-body";
@import "sass/object/project/chat";
@import "sass/object/project/chat-toolbar";
@import "sass/object/project/chat-tool-tab";
@import "sass/object/project/chat-tool-audio";
@import "sass/object/project/comment";
@import "sass/object/project/vote";
@import "sass/object/project/general-comment";
@import "sass/object/project/general-comment-post";
@import "sass/object/project/sentence-comment";
@import "sass/object/project/livevideo-debate-prepdocument";
@import "sass/object/project/livevideo-debate-settingenv";
@import "sass/object/project/livevideo-infopannel";
@import "sass/object/project/livevideo-debate-debate-teampannel";
@import "sass/object/project/media-choice";
@import "sass/object/project/event-context";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
