.c-badge {
  display: inline-block;
  padding: 1px 4px;
  border-radius: 2px;
  background-color: #333;
  color: #fff;
  font-size: 1rem;

  &.is-prop {
    background-color: $color-brand-prop;
  }

  &.is-opp {
    background-color: $color-brand-opp;
  }
}
