// Button
//
// Button
//
// Markup:
// <button class="c-btn {{modifier_class}}">button</button>
//
// .is-small - small
// .is-xs - extra small
// .is-inverse - inverse
// .is-block - block level
//
// Styleguide 1.1
.c-btn {
  display: inline-block;
  margin: 0;
  padding: 9px 14px 9px;
  border: 1px solid $color-brand;
  border-radius: 3px;
  background-color: $color-brand;
  color: #fff;
  font-size: 1.4em;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out, color 0.2s ease-out;
  vertical-align: middle;

  &:hover,
  &:active,
  &:focus,
  &:visited,
  &:link {
    background-color: darken($color-brand, 10);
  }

  &.is-small {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &.is-xs {
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 1.2rem;
  }

  &:active {
    box-shadow: inset 0px 2px 4px 0px rgba(4, 0, 0, 0.29);
  }

  &.is-inverse {
    background-color: transparent;
    color: $color-brand;
  }

  &.is-inverse:hover,
  &.is-inverse:active,
  &.is-inverse:focus,
  &.is-inverse:visited,
  &.is-inverse:link {
    background-color: $color-brand;
    color: #fff;
  }

  &.is-prop.is-inverse {
    border-color: $color-brand-prop;
    background-color: transparent;
    color: $color-brand-prop;
  }

  &.is-prop.is-inverse:hover,
  &.is-prop.is-inverse:active,
  &.is-prop.is-inverse:focus,
  &.is-prop.is-inverse:visited,
  &.is-prop.is-inverse:link {
    background-color: $color-brand-prop;
    color: #fff;
  }

  &.is-opp.is-inverse {
    border-color: $color-brand-opp;
    background-color: transparent;
    color: $color-brand-opp;
  }

  &.is-opp.is-inverse:hover,
  &.is-opp.is-inverse:active,
  &.is-opp.is-inverse:focus,
  &.is-opp.is-inverse:visited,
  &.is-opp.is-inverse:link {
    background-color: $color-brand-opp;
    color: #fff;
  }

  &.is-block {
    width: 100%;
  }
}

.fb_button {
  background-color: #3b5998;
  color: white;

  i {
    color: white;
  }
}
