.p-debate-group {
  list-style-type: none;
  margin: 0;
  padding: 0;
  @include clearfix;

  &__member {
    width: 26px;
    height: 26px;
    display: block;
    margin-right: 4px;
    margin-bottom: 4px;
    float: left;
  }

  &__member-add {
    width: 26px;
    height: 26px;
    display: block;
    float: left;
    border-radius: 50%;
    border: 1px solid $color-brand;
    background-color: #fff;
    transition: color 0.2s ease-out, background-color 0.2s ease-out;
    color: $color-brand;

    &:hover,
    &:active,
    &:focus,
    &:link,
    &:visited {
      background-color: $color-brand;
      color: #fff;
    }
  }
}
