.p-menu-account {
  display: none;
  position: absolute;
  top: 28px;
  right: 14px;

  &__notify {
    position: relative;
    display: inline-block;
    top: 2px;
    margin-right: 7px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background-color: lighten($color-brand-secondary, 10%);
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    transition: background-color 0.2s ease-in;

    &:hover,
    &:focus,
    &:active {
      background-color: lighten($color-brand-secondary, 5%);
      color: #fff;
    }
  }

  &__notify-count {
    display: inline-block;
    padding: 1px;
    line-height: 1.2;
    border-radius: 3px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.7rem;
    background-color: $color-brand-alert;
  }

  &__icon {
    > img {
      width: 41px;
      height: 41px;
      border: 4px solid rgba(255, 255, 255, 0.2);
    }

    &:hover {
      > img {
        border: 4px solid rgba(255, 255, 255, 0.1);
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}
