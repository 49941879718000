$settinenv-right: 150px;
$settinenv-center-margine: 20px;

.setting-env {
  position: relative;
  padding: 10px;
  height: 100%;

  &__center {
    display: inline-block;
    text-align: center;
    width: calc(#{$aside-chat-width-pc} - #{$settinenv-center-margine});
  }

  &__leftbottom {
    position: absolute;
    left: 0px;
    bottom: 10px;
  }
}
