/*
.underdebate-doc .LiveVideoDebate-PrepDocEach{
    display:block !important;
}
.underdebate-doc .LiveVideoDebate-PrepDocEach__body {
    width: 100%;
}


.doc-heading {
    display: inline-block;
    width: 35px;
    white-space: nowrap;
}

*/
