.p-debate-intro {
  margin-bottom: 35px;

  &__heading {
    display: inline-block;
    margin: 0;
    margin-bottom: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 14px;
    line-height: 1;
    color: #fff;

    &.is-prop {
      background-color: $color-brand-prop;
    }

    &.is-opp {
      background-color: $color-brand-opp;
    }
  }

  &__body {
    padding-bottom: 0;
    font-family: "Merriweather", serif;
    background-color: #f6f7f9;
    padding: 10px;
    border-radius: 10px;
  }
}
