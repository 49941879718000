.p-argument-header {
  margin-bottom: 28px;

  &__number {
    display: block;
    margin-bottom: 10px;
    font-size: 1.8rem;
    font-family: "Merriweather", serif;
    font-style: italic;
  }

  &__text-wrapper {
    font-size: 2rem;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  &__text {
    display: inline;

    font-family: "Merriweather", serif;

    @media screen and (min-width: 768px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.3rem;
    }

    line-height: 1.5;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &.is-prop {
    .p-argument-header__text {
      background-color: $color-brand-prop;
      box-shadow: 0.5em 0 0 0 $color-brand-prop, -0.5em 0 0 0 $color-brand-prop;
      color: #fff;
    }
  }

  &.is-opp {
    text-align: right;

    .p-argument-header__text {
      background-color: $color-brand-opp;
      box-shadow: 0.5em 0 0 0 $color-brand-opp, -0.5em 0 0 0 $color-brand-opp;
      color: #fff;
    }
  }
}
