// =========================================================================
// Font Import
// @FIXME import error!
// ==========================================================================
// @import 'https://fonts.googleapis.com/css?family=Noto+Sans:400,700';
// @import 'https://fonts.googleapis.com/css?family=Merriweather:400,700';
// =========================================================================
// Style
// =========================================================================
*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  background-color: $base-background-color;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #354052;
}

a {
  color: $color-brand;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: darken($color-brand, 10%);
    text-decoration: underline;
  }
}

// =========================================================================
// Utility
// =========================================================================
.visually-hidden {
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.spinner-box {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner-box > div {
  background-color: #ccc;
  height: 100%;
  width: 6px;
  margin-left: 1px;
  margin-right: 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner-box .spinner-box-rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner-box .spinner-box-rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner-box .spinner-box-rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner-box .spinner-box-rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
