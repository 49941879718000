.media-list-users {
  .list-group-item {
    margin-top: 0;
    padding: 15px;
  }
  .media-object {
    width: 42px;
    margin-right: 5px;
    border-radius: 100%;
  }
  .media-body-secondary {
    color: $gray;
  }
  .media-body p:last-child {
    margin-bottom: 0;
  }
  .btn.pull-right {
    margin-top: 7px;
  }
}

.modal-body .media-list-users {
  margin-bottom: 5px;

  .list-group-item {
    border-left: 0;
    border-right: 0;
  }
  .list-group-item:last-child {
    @include border-radius(4);
    border-bottom: 0;
  }
  .list-group-item:first-child {
    @include border-radius(4);
    border-top: 0;
  }
}
