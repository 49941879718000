$user_color_active: #f5f5dc;
$user_color_deactive: gray;

/**sizes**/

$microphone-width: 80px;
//livevideo debate common
$aside-chat-width-pc: 320px;
$aside-roomuser-width-pc: 200px;
$aside-chat-width-sp: 0px;
$aside-roomuser-width-sp: 100px;
$setting-env-height: 130px;
$livevideo-debate-header-content-height: 35px;
$livevideo-debate-header-content-height-sp: 60px;
$livevideo-debate-vertical-margine: 16px;
$livevideo-debate-horizontal-margine: 8px;
$livevideo-debate-vertical-layoutpadding: 8px;
$livevideo-debate-horizontal-layoutpadding: 4px;
$livevideo-debate-header-height: 51px;
$livevideo-debate-footer-height: 85px;
$livevideo-debate-second-bottom-sp: 133px;
$livevideo-debate-second-bottom-reaction-sp: 50px;
$livevideo-debate-second-bottom-chat-sp: $livevideo-debate-second-bottom-sp - $livevideo-debate-second-bottom-reaction-sp;
$livevideo-debate-footer-height-sp: 56px;
$livevideo-debate-footer-padding-sp: 4px;
$livevideo-debate-microphone-height-sp: $livevideo-debate-footer-height-sp - $livevideo-debate-footer-padding-sp -
  $livevideo-debate-footer-padding-sp;
$livevideo-debate-mobile-max: 767px;
$livevideo-debate-tablet-min: 768px;

$user-panel-width-sp: 80px;

//livevideo debate introduction
$intro_motion_height_PC: 70px;
$intro_motion_height_SP: 70px;
$intro_team_selection_height_PC: 230px;
$intro_team_selection_height_SP: 150px;
$intro_info_height: 200px;
//livevideo debate preparation
//livevideo debate debate
$debate_video_controller_height: 60px;
// $poi_area_min_height: 130px;
// $poi_area_width: 240px;
//users
$video_img_size_sideteam_sp: 50px;
$video_img_size_sideteam_pc: 70px;
$video_img_size_intro_sp: 50px;
$video_img_size_intro_pc: 80px;
$video_img_size_prep: 50px;
$video_img_size_debate_below_team: 50px;
$video_img_size_debate_below_audience: 50px;
$video_img_size_main_speaker: 100px;
$video_img_size_poi_speaker: 70px;
$video_img_size_reflec: 30px;
//livevideo debate reflection
// paths
// break points
//
$transition_button_height: 67px;
$prep_team_pannel_width: 70px;
$debate_center_fixed_width: 500px;
// $debate_video_height_speakerview: 280px;
// $debate_video_height_speechstatus: 45px;
$transition_button_height: 67px;
$debate-mainspeaker-height: 155px;
$debate-roleorder-height: 0px;
$debate-poipannel-height: 0px;
$debate-speech-container-height: $debate-mainspeaker-height + $debate-roleorder-height + $debate-poipannel-height;
// $debate-document-panel-height
// $reflection_bottom_PC: 45px;
// $reflection_bottom_SP: 45px;
$sp-overlay-bigchat-header-height: 44px;
$sp-overlay-bigchat-body-height-elimination: $livevideo-debate-header-content-height-sp + $livevideo-debate-footer-height-sp +
  $debate-mainspeaker-height + $sp-overlay-bigchat-header-height;
