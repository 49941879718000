.p-debate {
  margin-bottom: 42px;

  &__date {
    margin-bottom: 7px;

    time {
      font-family: "Merriweather", serif;
      color: $color-brand-light-gray;

      @media screen and (min-width: 768px) {
        font-size: 1.8rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.2rem;
      }
    }
  }

  &__state {
    margin-left: 7px;
  }

  &__heading {
    margin: 0;
    margin-bottom: 35px;
    @media screen and (min-width: 768px) {
      font-size: 3.2rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
    line-height: 1.4;
    font-weight: bold;
    font-family: "Merriweather", serif;
  }

  &__heading-sub {
    font-size: 0.7em;
    color: $color-brand-light-gray;
    display: block;
    font-weight: normal;
  }
}
