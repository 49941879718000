.debate_team_container {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

$eah_team_margin: 2px;

.debate_team_container .each_team {
  background-color: white;
  width: calc(50% - #{$eah_team_margin} * 2);
  margin: $eah_team_margin;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.debate_team_container .each_team__heading-container {
  text-align: center;
}

.debate_team_container .each_team__teammember-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  min-height: 30px;
}

.debate_team_container .each_team__heading {
  padding: 2px 7px;
  font-weight: bold;
  text-decoration: underline;
}
.debate_team_container .each_team__heading.is-proposition,
.debate_team_container .each_team__heading.is-gov {
  color: $color-team-gov;
}

.debate_team_container .each_team__heading.is-opposition,
.debate_team_container .each_team__heading.is-opp {
  color: $color-team-opp;
}

.debate_audience_container {
  text-align: center;
}

.debate_audience_container .audience {
  padding: 2px 5px;
  margin: 4px 10px;
  width: 54px;
  height: 74px;
  display: inline-block;
}
